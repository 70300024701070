// Stats

.stats {
    .main__content {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 0;

        .avatar-feature-image {
            max-height: 64px;
        }
    }
}