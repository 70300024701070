// Header

.main__header {
    background: #F0F0F0;
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    right: 0;
    left: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;

    .main__header__home-link {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        position: absolute;
        left: 15px;

        width: 32px;
        height: 32px;
        background: #ffffff;
        border-radius: 100%;

        @media screen and (min-width: $screen-m) {
            width: auto;
            height: auto;
            background: transparent;
        }

        span {
            display: none;

            @media screen and (min-width: $screen-m) {
                display: block;
            }
        } 

        i {
            color: $maif-red;

            @media screen and (min-width: $screen-m) {
                margin-right: 10px;
            }
        }
    }

    .main__title {
    
    }

    .btn-interface {
        position: absolute;
        right: 15px;

        span {
            @media screen and (max-width: $screen-m) {
                padding: 0;
                width: 30px;
                height: 30px;
            }

            span {
                display: none;

                @media screen and (min-width: $screen-m) {
                    display: inline-block;
                }
            }
        }
    }
}