// Feedback

.feedback {
    .main__content {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 0;
    }

    .feedback__title {
        @include font-size(40);
        font-weight: 700;
        margin-bottom: 50px;
        display: flex;
        align-items: center;

        i {
            margin-right: 15px;
            color: $maif-red;
        }
    }
}