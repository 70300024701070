// Page

.page {
    .main__content {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 0;
    }

    .page__title {
        @include font-size(40);
        font-weight: 700;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .heading {
        margin-top: 30px;
        font-weight: 600;
    }

    .page-content {
        .picture-illustration {
            display: block;
            margin: 30px auto;
            width: 600px;
            height: auto;
            max-width: 100%;
        }
    }
}