// Creator

.creator {
    height: 100vh;
    overflow: hidden;

    #avatar-creator-container {
        .creator__app {
            width: 100%;
            height: calc(100vh - 50px);
            display: flex;
            flex-direction: column;
            background: linear-gradient(342.23deg, #F0F0F0 3.7%, #FFFFFF 92.35%, rgba(240, 240, 240, 0) 92.36%);

            @media screen and (min-width: $screen-l) {
                flex-direction: row;
            }
        }

        .creator__app__avatar {
            flex-shrink: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 30vh;

            @media screen and (min-width: $screen-l) {
                height: auto;
                width: 35%;
                align-items: flex-end;
                padding-right: 5vw;
            }

            .avatar__downloads__container {
                @media screen and (min-width: $screen-l) {
                    position: absolute;
                    bottom: 30px;
                    right: 5vw;
                    width: 25vw
                }

                .avatar__downloads {
                    @media screen and (min-width: $screen-l) {
                        display: flex;
                        justify-content: center;
                    }

                    button {
                        flex-shrink: 0;
                        margin: 0 5px;
                        padding: 12px 18px;
                    }
                }

                .avatar__downloads__validation {
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .creator__app__custom {
            flex-shrink: 0;
            width: 100%;
            background: #ffffff;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            box-shadow: 0px -10px 15px #dadada;
            padding-top: 25px;
            position: relative;

            @media screen and (min-width: $screen-l) {
                width: 65%;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                box-shadow: none;
                padding-top: 0;
            }
            
            .creator__app__custom__category {
                height: 70px;
                display: flex;
                align-items: center;

                @media screen and (min-width: $screen-l) {
                    height: 110px;
                }

                .left-button, .right-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    width: 40px;
                    height: 40px;

                    @media screen and (min-width: $screen-l) {
                        display: none;
                    }
                }

                .creator__app__custom__category__list-container {
                    position: relative;
                    overflow: hidden;
                    width: 100%;

                    @media screen and (min-width: $screen-l) {
                        overflow: auto;
                        padding: 0 45px;
                    }
                    
                    &::before {
                        content: '';
                        display: block;
                        width: 5px;
                        height: 40px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);

                        @media screen and (min-width: $screen-l) {
                            display: none;
                        }
                    }

                    &::after {
                        content: '';
                        display: block;
                        width: 5px;
                        height: 40px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);

                        @media screen and (min-width: $screen-l) {
                            display: none;
                        }
                    }
                }

                .creator__app__custom__category__list {
                    display: flex;
                    list-style-type: none;
                    flex-wrap: nowrap;
                    -webkit-overflow-scrolling: touch;
                    margin-left: 0;
                    margin-right: 0;
                    padding: 0;
                    margin: 0;
                    overflow-x: auto;
                    overscroll-behavior-x: contain;
                    scroll-snap-type: x mandatory;
                    scroll-behavior: smooth;
                    scrollbar-width: none;
                    -ms-overflow-style: none;

                    @media screen and (min-width: $screen-l) {
                        padding: 5px 0 5px 0;
                        flex-wrap: wrap;
                    }

                    &::-webkit-scrollbar {
                        display: none;
                    }
                   
                    .creator__app__custom__category__list-entry {
                        flex-shrink: 0;
                        margin: 0 5px;
                        width: 130px;
                        // scroll-snap-align: start;

                        @media screen and (min-width: $screen-l) {
                            width: auto;
                            margin: 5px;
                        }
            
                        a {
                            display: flex;
                            height: 40px;
                            padding: 0 30px;
                            background: #F0F0F0;
                            border: 1px solid #CFCFCF;
                            color: #000000;
                            border-radius: 80px;
                            justify-content: center;
                            align-items: center;
                        }

                        .active {
                            background: #525B85;
                            border: 1px solid #525B85;
                            color: #FFFFFF;
                        }
                    }
                }
            }
            
            .creator__app__custom__entry {
                @media screen and (min-width: $screen-l) {
                    padding-top: 50px;
                }

                .custom__entry__title {
                    display: none;
                    padding: 0 15px;
                    height: 40px;

                    @media screen and (min-width: $screen-m) {
                        padding: 0 50px;
                    }

                    @media screen and (min-width: $screen-l) {
                        display: block;
                        height: 120px;
                    }

                    h2 {
                        @include font-size(24);
                        font-weight: 700;
                        position: absolute;
                        top: 18px;
                        left: 0;
                        width: 100%;
                        text-align: center;

                        @media screen and (min-width: $screen-l) {
                            @include font-size(52);
                            position: static;
                            text-align: left;
                        }
                    }

                    p {
                        @include font-size(18);
                        font-weight: 700;
                        padding-bottom: 12px;
                        margin: 0;
                        border-bottom: 1px solid #ececec;

                        @media screen and (min-width: $screen-l) {
                            padding-bottom: 15px;
                        }
                    }
                }

                .tab-content {
                    .custom__entry__list-content {
                        overflow-y: auto;
                        padding-top: 15px;
                        padding-bottom: 100px;
                        height: calc(70vh - 255px);
                        border-top: 1px solid #ececec;

                        @media screen and (min-width: $screen-l) {
                            border-top: none;
                            padding-bottom: 30px;
                            height: calc(100vh - 440px);

                        }
                    }

                    .custom__entry__list {
                        padding: 0 15px;
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        gap: 15px;

                        @media screen and (min-width: $screen-s) {
                            grid-template-columns: repeat(4, 1fr);
                        }

                        @media screen and (min-width: $screen-m) {
                            padding: 0 50px;
                        }

                        @media screen and (min-width: $screen-l) {
                            grid-template-columns: repeat(3, 1fr);
                        }

                        @media screen and (min-width: $screen-xl) {
                            grid-template-columns: repeat(5, 1fr);
                        }
                        
                        .custom__entry__list__entry {
                            overflow: hidden;
                            border: 1px solid #cccccc;
                            border-radius: 12px;
                            position: relative;
                            transition: all 0.3s ease-in-out;

                            &::after {
                                content:'';
                                display: inline-block;
                                padding-top: 100%;
                            }

                            img {
                                position: absolute;
                                top: 0; right: 0; bottom: 0; left: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }

                            &:hover {
                                transform: scale(1.03);
                                box-shadow: 0 0 10px #cccccc;
                            }
                        }

                        .selected {
                            transform: scale(1.03);
                            box-shadow: 0 0 10px #cccccc;
                            outline: 3px solid #525B85;
                            border: none;
                        }
                    }
                }

                .custom__entry__action {
                    position: fixed;
                    bottom: 30px;
                    width: 100%;
                    left: 0;
                    right: 0;
                    display: flex;
                    justify-content: space-between;
                    padding: 15px;
                    border-top: 1px solid #ececec;
                    background: #ffffff;
                    height: 80px;

                    @media screen and (min-width: $screen-l) {
                        width: 65%;
                        padding: 15px 50px;
                        left: auto;
                        right: 0;
                    }

                    .btn {
                        @media screen and (max-width: $screen-m) {
                            width: 49%;
                        }
                    }

                    .btn-secondary {
                        background: #ffffff;
                    }
                }
            }
        }
    }
}

.creator-user-picture {
    position: fixed;
    z-index: 5;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);

    .creator-user-picture-container {
        position: fixed;
        top: 50px;
        left: 0;
        bottom: 0;
        right: 0;
        background: #ffffff;

        @media screen and (min-width: $screen-l) {
            border: 3vh solid #ffffff;
            top: 15vh;
            left: auto;
            bottom: auto;
            right: auto;
        }
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: auto;

        @media screen and (min-width: $screen-l) {
            width: auto;
            height: 65vh;
        }
    }

    .creator-capture-link {
        position: absolute;
        z-index: 10;
        bottom: 45px;
        left: 0;
        right: 0;

        @media screen and (min-width: $screen-l) {
            bottom: 30px;
        }
    }
}