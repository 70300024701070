// Home

.home {
    .main__header {
        .main__header__home-link {
            display: none;
        }
    }

    .main__content {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .home__main-picture {
        height: 150px;
        width: auto;
        margin-bottom: 15px;

        @media screen and (min-width: $screen-l) {
            width: auto;
            height: 23vw;
            margin-bottom: 30px;
        }
    }

    .home__title {
        @include font-size(24);
        @include line-height(24);
        text-align: center;
        margin-bottom: 30px;

        @media screen and (min-width: $screen-l) {
            @include font-size(52);
            @include line-height(52);
        }

        span {
            font-weight: 900;
            @include font-size(32);
            @include line-height(32);

            @media screen and (min-width: $screen-l) {
                @include font-size(52);
                @include line-height(52);
            }
        }
    }

    .home__selection {
        p {
            font-weight: 700;;
        }

        .home__selection-links {
            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: flex;

                li {
                    @media screen and (min-width: $screen-l) {
                        width: 265px;
                    }

                    svg {
                        height: 48px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    .home__datas {
        position: fixed;
        bottom: 30px;

        @media screen and (min-width: $screen-l) {
            right: 15px;
        }
    }
}

.home--photo-upload {
    .main__header {
        .main__header__home-link {
            display: flex;
        }
    }

    .home__selection {
        .home__selection-links {
            ul {
                flex-direction: column;

                @media screen and (min-width: $screen-m) {
                    flex-direction: row;
                }

                li {
                    width: auto;
                    margin-bottom: 15px;

                    @media screen and (min-width: $screen-m) {
                        margin: 0 5px;
                    }
                }
            }
        }
    }  
}