// Variables

//Breaking points
$screen-xs:       320px;
$screen-s:        576px;
$screen-m:        768px;
$screen-l:        992px;
$screen-xl:       1200px;
$screen-xxl:      1600px;

$maif-red: #C62222;