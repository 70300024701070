// Footer

.main__footer {
    background: #F0F0F0;
    position: fixed;
    z-index: 5;
    left: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            margin: 0 5px;
        }

        a {
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 0.8rem;
            line-height: 0.8rem;
            font-weight: 500;
        }
    }
}