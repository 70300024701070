// End

.end {
    .main__content {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 0;
    }

    .end__title {
        @include font-size(40);
        font-weight: 700;
    }

    .end__subtitle {
        @include font-size(32);
    }

    .end__picture {
        margin: 30px 0;
        padding: 0 15px;
        max-width: 100%;

        svg {
            width: 100%;
            height: auto;
        }
    }

    .end__selection-links {
        margin-bottom: 50px;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;

            @media screen and (min-width: $screen-m) {
                flex-direction: row;
            }

            li {
                width: auto;
                margin-bottom: 15px;

                @media screen and (min-width: $screen-m) {
                    margin: 0 5px;
                }
            }
        }
    }

    .end__retry-link {
        font-weight: 700;
        display: flex;
        align-items: center;

        i {
            color: $maif-red;
            margin-right: 5px;
        }
    }
}