.avatar__picture__container {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 25vh;
  height: 25vh;
  border-radius: 100%;
  border: 1vh solid #F0F0F0;
  overflow: hidden;
  box-shadow: 5px 5px 15px #898989;

  @media screen and (min-width: $screen-l) {
    width: 25vw;
    height: 25vw;
    max-width: 320px;
    max-height: 320px;
    border: 15px solid #F0F0F0;
  }

  .avatar__picture {
    width: 100%;
  }
}