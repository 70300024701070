// Mosaic

.mosaic {
    .page__title {
        .df-title {
            color: $maif-red;
            font-weight: 400;

            span {
                font-weight: 600;
            }
        }
    }
    
    .mosaic-col {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        cursor: pointer;
        transition: all 0.4s ease-in-out;

        &:after {
            padding-top: 100%;
            display: block;
            content: '';
        }

        &:hover {
            transform: scale(1.2);
        }

        .card-container, .front, .back {
            width: 100%;
            height: 100%;
        }

        .card-container {
            position: relative;
        }

        .front, .back {
            position: absolute;
            top: 0;
            left: 0;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            overflow: hidden;
            transition: transform .8s ease;
            border-radius: 100%;
            box-shadow: 0 0 5px rgba(0, 0, 0, .25);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 1.2vw solid #ffffff;
            

            @media screen and (min-width: $screen-s) {
                border: 0.7vw solid #ffffff;
            }

            @media screen and (min-width: $screen-l) {
                border: 0.4vw solid #ffffff;
            }

            @media screen and (min-width: $screen-xl) {
                border: 4px solid #ffffff;
            }

            img {
                width: 100%;
                height: auto;
                max-width: 100%;
            }

            p {
                text-align: center;
                font-size: 3.5vw;
                line-height: 4vw;
                margin: 0;
                color: #ffffff;

                span {
                    font-weight: 600;
                }

                @media screen and (min-width: $screen-s) {
                    font-size: 0.8rem;
                    line-height: 1rem;
                }
                @media screen and (min-width: $screen-m) {
                    font-size: 1.2rem;
                    line-height: 1.4rem;
                }
                @media screen and (min-width: $screen-l) {
                    font-size: 0.7rem;
                    line-height: 0.9rem;
                }
                @media screen and (min-width: $screen-xl) {
                    font-size: 0.9rem;
                    line-height: 1.1rem;
                }
            }
        }

        .front {
            background: #d5d5d5;
        }

        .back {
            transform: rotateY(180deg);
            background: $maif-red;
        }

        .card-container:hover .front {
            // transform: rotateY(-180deg);
        }

        .card-container:hover .back {
            // transform: rotateY(0deg);
        }
        
        .card-container {
            perspective: 75rem;
        }
    }
}

.mosaic-3d {
    .page-content {
        .link-highlight {
            @media screen and (max-width: $screen-m) {
                position: fixed;
                bottom: 50px;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;
            }
        }
        .mosaic-link {
            display: flex;
            width: 50px;
            height: 50px;
        }

        .mosaic-col {
            width: 100%;
            height: 100%;

            .front {
                border: 3px solid #ffffff;
            }
        }
    }

    #all {
        width: 100px;
        height: 5px;
        -webkit-animation: grow 4s;
      /*   animation-timing-function: ease-in; */
          -webkit-animation-delay:0.5s;
        animation-fill-mode: forwards;
        /*Safari and Chrome*/
        background: $maif-red;
        border-radius: 15px;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            width: 350px;
            position: absolute; 
            bottom: 100px;
        }
    }
      
    @-webkit-keyframes grow {
        from {
            width: 0px;
        }
        to {
            width: 300px;
        }
    }
    
    #div-sphere {
        position: relative;
        width: 250px;
        height: 250px;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    #div-sphere a {
        position: absolute;
        top: 0px;
        left: 0px;
    }


    .polyhedron-content {
        animation: polyhedron 0.8s ease-in-out 4s;

        @media screen and (min-width: $screen-m) {
            transform: scale(1.5);
        }

        @-webkit-keyframes polyhedron {
            0% {
                transform: scale(0);
            }
            90% {
                transform: scale(1.2);
            }
            100% {
                transform: scale(1);
            }
        }

        .polyhedron {
            position: relative;
            padding: 0;
            width: 16em; height: 16em;
            perspective: 100em;
            transform-style: preserve-3d;
            animation: rot 40s infinite linear;

            .df-title {
                color: #ffffff;
                font-weight: 400;
                font-size: 28px;
                text-align: center;
                position: absolute;
                z-index: 2;
                transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(75%);
    
                span {
                    font-weight: 600;
                }
            }

            .df-title-merci {
                transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(200%);
            }
        }

        @keyframes rot {
            from { transform: rotateZ(-15deg) rotateX(45deg) rotateY(0deg); }
            to { transform: rotateZ(345deg) rotateX(-315deg) rotateY(360deg); }
        }

        .side, .side:after {
            overflow: hidden;
            position: absolute;
            width: inherit; height: inherit;
            // backface-visibility: hidden;
        }

        .triangle:after {
            transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-50%);
            content: '';
        }

        .tetrahedron .side.triangle:first-child {
            transform: translateZ(4.6188em) 
            rotateX(19.47deg) 
            rotate(30deg) skewY(30deg) scaleX(.866);
        }

        .tetrahedron .side.triangle:first-child:after {
            background: $maif-red;
        }

        .tetrahedron .side.triangle:nth-child(2) {
            transform: rotateY(120deg) translateZ(4.6188em) 
            rotateX(19.47deg) 
            rotate(30deg) skewY(30deg) scaleX(.866);
        }

        .tetrahedron .side.triangle:nth-child(2):after {
            background: $maif-red;
        }

        .tetrahedron .side.triangle:nth-child(3) {
            transform: rotateY(-120deg) translateZ(4.6188em) 
            rotateX(19.47deg) 
            rotate(30deg) skewY(30deg) scaleX(.866);
        }

        .tetrahedron .side.triangle:nth-child(3):after {
            background: $maif-red;
        }

        .tetrahedron .side.triangle:last-child {
            transform: translateZ(4.6188em) 
            rotateX(90deg) rotateY(180deg)
            rotate(30deg) skewY(30deg) scaleX(.866);
        }

        .tetrahedron .side.triangle:last-child:after {
            background: $maif-red; 
        }
    }
}