// Global

.debug {
	outline: 1px solid red;
}

* {
	box-sizing: border-box;
}

html, body {
	margin:0;
	padding: 0;
}

html {
	scroll-behavior: smooth;
	height: -webkit-fill-available;
}

body {
	font-family: maif, serif;
	@include font-size(16);
	min-height: 100vh;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
	body {
		/* The hack for Safari */
		min-height: -webkit-fill-available;
	}
}

.main__wrapper {
    padding-top: 50px;
}