// Loader

.page-loading {
    position: fixed;
    top: 50px;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text {
        color: #ffffff;
        font-size: 1.5rem;
    }
}

.modale-loading {
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}